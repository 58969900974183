$(document).ready(function(){
    
    /**
     * Used for the back office calendar view
     */
    if($('body.room-bookings.calendar').length>0)
    {
        $('.calendar td:has(div)')
            .css({cursor:'pointer'})
            .click(function(e){
                $.fancybox({
                    autoDimensions:false,
                    autoScale:false,
                    height:$(window).height()/2,
                    content:$(e.currentTarget).find('div').clone(true),
                    width:$(window).width()/2
                });
            });
    }
    /**
     * Used for the Room booking form
     */
    if($('#room-booking-form').length>0)
    {
        // Set up the room selector handler
        $('[name=room]').unbind().change(function(e){

            // Target the selected one
            if($(e.currentTarget).is(':checked')) 
            {
                var URL = $('[name=ReturnURL]').val();
                var $this = $(this);            
                var $fieldset = $this.closest('fieldset').addClass('valid'); // Set the fieldset to "valid" - this then displays the big down arrow indicating "proceed to the next step"
                var $next = $('.'+$fieldset.data('next')).addClass('show').addClass('loading'); // Set the next fieldset to displayt the loader graphic
                $this.closest('label').addClass('selected').siblings().removeClass('selected'); // Set appropriate classes to draw/remove the big checkmarks etc.            
                $('[name*=times]').remove();// Remove all bookings thus far and re-hide the payment form
                $('.s2').removeClass('valid');
                $('.s3').removeClass('show');

                // Load the calendar and time-selector display
                $.ajax({
                    url: URL+'?'+$('#room-booking-form').serialize()+'&ajax='+$fieldset.data('next'),
                    success:function(data){
                        $next.children().not('legend').remove();
                        $next.append(data.dateSelector+data.timeSelector).removeClass('loading');                    

                        // Set up handlers on the available days
                        $('td[data-date]').not('.unavailable').click(function(e){$('[name=date]').val($(this).data('date'));$('[name=room]:checked').trigger('change');});

                        // Set up the handler on the times-selector
                        $('[name*=times]').change(function(e){
                            var $s3 = $('.'+$next.data('next'));
                            // Toggle classes and set header based on times selected
                            $(e.currentTarget).closest('td').toggleClass('selected').closest('table').parent().find('h2').find('span').remove().end().append($('<span> ('+ $('.timeSelector .selected').length+' slot(s) selected)</span>'));
                            if($('.timeSelector .selected').length==0)
                            {
                                // No time selected, hide payment form and remove all content except the legend
                                $next.removeClass('valid');
                                $s3.removeClass('show').children().not('legend').remove();
                            }
                            else
                            {
                                // A time has been selected
                                $next.addClass('valid');
                                // Draw the payment form
                                $('#room-booking-form').unbind();// RElease the onsubmit interception
                                $.ajax({
                                    url: URL+'?'+$('#room-booking-form').serialize()+'&ajax='+$next.data('next'),
                                    success:function(data){
                                        $s3.children().not('legend').remove(); // Remove all content except the legend
                                        if(data.items.length>0)
                                        {
                                            // Call the js helper from the gateway class to create correctly structured inputs
                                            $(data.items).each(function(i,item){
                                                $s3.append(gatewayMakePaymentInput(i+1, item.uid, data.room.name, data.room.net, data.room.vat, 1, data.room.nominal));
                                            });
                                            $('[name=PaymentTotal]').val(data.total);
                                            // Nice display of booking
                                            $s3.append(data.display);// Could have done it here, but punting to PHP instead so that it can use the language file
                                            var btns = gatewayMakePaymentButtons();
                                            $s3.append(btns).find('button').css({
                                                width:(100 / btns.length) + '%'
                                            });
                                            // Intercept the form submission to save the booking
                                            $('#room-booking-form').submit(function(e){
                                                $.ajax({
                                                    url: URL+'?'+$('#room-booking-form').serialize()+'&ajax=save',
                                                    success: function(r){
                                                        console.log(r);
                                                        if(r.booking && r.booking.ID)
                                                        {
                                                            $(['name=booking_id']).remove();
                                                            $('#room-booking-form').append('<input type="hidden" name="booking_id" value="'+r.booking.ID+'"/>').unbind().submit();
                                                        }
                                                    }
                                                });
                                                return false;
                                            });

                                            $s3.addClass('show'); // Show it
                                            if(data.items.length==1)
                                            {
                                                $('html, body').animate({scrollTop: $s3.offset().top}, 1000); // Do a nice scroll down to the next section to prompt the user
                                            }
                                        }
                                    }
                                });
                            }
                        });
                        $('html, body').animate({scrollTop: $next.offset().top}, 1000); // Do a nice scroll down to the next section to prompt the user
                    }
                });
            }
        });
    }
});