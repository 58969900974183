$(function() {

    // Per change request: 226, move the messageboxes above the current session
    //$('.messagebox').insertAfter('#pageTitle');// Taken out per http://bpma.orangeleafsystems.co.uk/view.php?id=178#750

    $('#top_menu_list a[title=Help]').attr('href','/admin/help?framed=1').addClass('fancybox-iframe');

    $('.onloadhide').hide();
    $('.onloadshow').show();

    $('.onloadhide').removeClass('onloadhide');
    $('.onloadshow').removeClass('onloadshow');

    $('select').chosen({allow_single_deselect:true,disable_search_threshold:60});
    $('.fancybox').fancybox({height:'75%',width:700});
    $('.fancybox-iframe').fancybox({
        height:'75%',
        onComplete: function(){
            $('#fancybox-frame').load(function(){
                //$(this).height( $(this).contents().find("body").height() );
            });
        },
        titleShow:false,
        type:'iframe',
        width:960
    });
    $('.has-date-picker').datepicker();

    if($('#editForm').children('fieldset').length > 1)
    {
        $('#editForm').append('<div id="editFormTabs" class="makeTabs"><ul><li></li></ul></div>');
        $('#editForm').children('fieldset').each(function(i,v){
            $('#editFormTabs>ul').append('<li><a href="#ttf'+i+'">'+$(v).children('legend').html()+'</a></li>');
            $('#editFormTabs').append('<div id="ttf'+i+'"></div>');
            $('#ttf'+i).append(v,true);
            $(v).css({border:'none',margin:0,padding:0})
            $(v).children('legend').css({display:'none'});
        });
        $('#editFormTabs').tabs({
            selected: $('#subformOpen').val(),
            select:function(event,ui){
                $('#subformOpen').val(ui.index);
            }
        });
    }
    try{$( "button, input[type=submit], a.button" ).button();}catch(e){}
    try{$( "#userEditForm input[type=submit], .adminListing td a.button" ).button();}catch(e){}
    try{$('input[name=preferred_date_display]').get(0).onchange = getBuckets;}catch(e){}
    try{$('input[name=end_date_display]').get(0).onchange = getBuckets;}catch(e){}

    if($('.staffmenu').length!=0 && $('.usermenu').length!=0)
    {
        $('.staffmenu').css({height:$('.usermenu').height()});
    }

    // Make "select-full-day" display
    rom_makeSelectFullDayDisplay();
});
/**
 * Invoked when a session is removed from the booking form selected-sessions list
 *
 * @param input
 *
 * @return void
 */
function onBucketRemove(input, callback)
{
    var $div = $(input).parent().parent();

    $("input[name='"+$(input).attr('name')+"']").attr('checked', false);

    $div.html('<h2><img src="/assets/images/icons/ajax-loader-circle-ball.gif" align="absmiddle"/> removing...</h2>');

    getBuckets(function(){
        $div.animate({opacity:0}, function(){
            $div.remove();
        });
        if(undefined!=callback)
        {
            callback();
        }
    });
}
/**
 * Bucket checkbox handler. Adds to selected sessions
 *
 * @param input
 *
 * @return void
 */
function onBucketSelected(input, callback)
{
    var $li = $(input).parent().parent();
    if($li.find('input[type=checkbox]:checked').length)
    {
        var $c = $li.clone();
        $c.css({left:$li.offset().left, position:'absolute', top:$li.offset().top, zIndex:1000});
        $li.before($c);
        $c.animate({left:$('.selected-sessions').offset().left,opacity:0,top:$('.selected-sessions').offset().top},function(){
            $c.remove();
            $('.bucket.selected-sessions').append('<div class="row selected-sessions adding"><h2><img src="/assets/images/icons/ajax-loader-circle-ball.gif" align="absmiddle"/> Adding...</h2></div>');
            getBuckets(callback);
        });
    }
    else
    {
        onBucketRemove($(".selected-sessions input[name='"+$(input).attr('name')+"']", callback));
    }
}
/**
 * Use ajax submission of the booking form to retrieve the latest buckets
 *
 * @param callback
 *
 * @return void
 */
function getBuckets(callback)
{
    try{
        $('#buckets-display').addClass('loading');$('#availability-display').removeClass('loading');$('#confirm-display').removeClass('loading');
        $('#buckets-display li.bucket.session').animate({opacity:0.25});$('#availability-display>div').animate({opacity:0.25});$('#confirm-display>div').animate({opacity:0.25});
        var data = $('#booking-form').serialize()+'&buckets=1';
        $('#buckets-display input').attr('disabled', true);
        $.ajax({
            data:data,
            type:'POST',
            url:'/book',
            success:function(r){
                //console.log(r);
                $('#buckets-display').removeClass('loading');
                $('.bucket.selected-sessions .adding').remove();
                $('#buckets-display li.bucket.session').animate({opacity:1});
                $('#buckets-display>div').html("<div>"+r.buckets+"<div class=\"clear\"></div></div>");
                $('#buckets-display>div').fadeIn();
                $('#availability-display').removeClass('loading');
                $('#availability-display>div').animate({opacity:1});
                $('#availability-display>div').html(r.availability);
                $('#availability-display>div').fadeIn();
                $('#confirm-display').parent().animate({opacity:1});
                $('#confirm-display').removeClass('loading');
                $('#confirm-display>div').animate({opacity:1});
                $('#confirm-display>div').html(r.confirm);
                $('#confirm-display>div').fadeIn();
                $('.onloadhide').hide();
                $('input[type=submit]').button();
                $('#buckets-display input').attr('disabled', false);

                // Belt and braces
                $('.unavailable input').attr('disabled', true);

                if($('.items-unavailable input:checked').length>0)
                {
                    $('.submit').hide();
                }

                try{
                    if(undefined!=callback)
                    {
                        callback();
                    }
                }catch(e){

                }

                rom_makeSelectFullDayDisplay();
        }});
    }catch(e){
    }
}
/**
 * Resize an iframe based on height
 *
 * @param f
 * @param h
 */
function resizeIFrame(f, h)
{
    $(f).height((parseInt(h)+75)+'px');
}
/**
 * Validate a form
 *
 * @param f
 * @returns {Boolean}
 */
function validateForm(f)
{
    var ret = true;
    $(f).find('input,textarea,select').each(function(i,v){
        try{
            if(!v.onchange())
            {
                $(v).parent().addClass('error');
                $(v).parent().find('.error').remove();
                $(v).after('<span class="error">'+$(v).attr('title')+'</span>');
                ret = false;
            }
        }catch(e){}
    });
    return ret;
}
/**
 * Handler for the find by refno autocomplete box on the MyOrders screen
 *
 * @param Object request
 * @param Object response
 */
function rom_addObjectAutocomplete(request, response)
{
    request.mode = 1;
    $.ajax({
        url: "/assets/popups/ajax-refno-autocomplete.php",
        dataType: "json",
        data: request,
        success: function( data ) {
            response($.map(data.results, function(item) {
                return {
                    label: item.title,
                    value: item.identifier
                };
            }));
        }
    });
}
/**
 * Handler for the change event on the autocomplete widget
 *
 * @param Object event
 * @param Object ui
 */
function rom_addObjectAutocompleteChange(event, ui)
{
    try{
        console.log(ui.item.value);
    }catch(e){

    }
    $.ajax({
        url: "/assets/popups/ajax-refno-autocomplete.php",
        dataType: "json",
        data: {identifier:ui.item.value, mode:2},
        success: function( data ) {
            try{
                console.log(data)
            }catch(e){

            }
            var orderable;
            try{orderable = data.orderable;}catch(e){orderable = false;}
            if(orderable!=true)
            {
                alert('Sorry, this item is not orderable');
                $(event.target).addClass('error');
            }
            else
            {
                $(event.target).removeClass('error');
            }
        }
    });
}
function rom_makeSelectFullDayDisplay()
{
    if($('#buckets-display'))
    {
        if($('#buckets-display input[type=checkbox]').length==12)// I.e. ROM is in "2 sessions per day" mode
        {
            $('#buckets-display .bucket.session ul').before('<div class="full-day-book"><label><div class="time">All day</div><input type="checkbox" class="full-day-checkbox"/></label></div>');
            $('.full-day-checkbox').each(function(i, v){
                var $v = $(v);
                if($v.parents('.full-day-book').next().find('input:checked').length==2)
                {
                    $v.attr('checked', true);
                }
                if($v.parents('.full-day-book').next().find('input:disabled').length!=0)
                {
                    $v.parents('.full-day-book').find('label,div').css('opacity',0.25);
                    $v.attr('disabled', true);
                }
                else
                {
                    $v.click(function(){
                        var inputs = $(this).parents('.full-day-book').next().find('input[type=checkbox]');
                        var input_2_name = $(inputs[1]).attr('name');// need to requery as this input will no longer exist after the buckets are redrawn
                        var checked = $v.attr('checked');
                        $(inputs[0]).attr('checked', checked);

                        if($v.attr('checked'))
                        {
                            // Select both sessions
                            onBucketSelected(inputs[0], function(){
                                var $input_2 = $('input[name='+input_2_name+']');
                                $input_2.attr('checked', checked);
                                onBucketSelected($input_2);
                            });
                        }
                        else
                        {
                            // Deselect sessions
                            onBucketRemove($(".selected-sessions input[name="+$(inputs[0]).attr('name')+"]"), function(){
                                var $input_2 = $('input[name='+input_2_name+']');
                                $input_2.attr('checked', checked);
                                onBucketRemove($(".selected-sessions input[name="+$input_2.attr('name')+"]"));
                            });
                        }
                    });
                }
            });
        }
    }
}
/**
 * Open the amend order popup
 *
 * @param string url The url to load into the popup
 *
 * @return void
 */
function rom_openAmendOrderPopup(url)
{
    $.fancybox({
        autoDimensions: false,
        autoScale: false,
        content: 'Loading... please wait...',
        width: 400
    });

    $.post(
        url,
        null,
        function( response ) {
            $('#fancybox-content').html(response);
            $('#fancybox-content input[type=submit]').button();
            $('#fancybox-content select').chosen();
            $('#fancybox-content input[type=submit]').click(function(){
                $('form[name=AdminList]').append('<input type="hidden" name="addByWishlist" value="'+$('#wlitems').val()+'"/>');
                $('form[name=AdminList]').append('<input type="hidden" name="addByRefNo" value="'+$('#refno').val()+'"/>');
                $('form[name=AdminList]').append('<input type="hidden" name="addObject" value="'+$('input[name=addObject]').val()+'"/>');
                $('form[name=AdminList]').append('<input type="hidden" name="ajaxsubmit" value="true"/>');
                $('form[name=AdminList]').submit();
                $('#fancybox-content').html('<p>Processing... please wait...');
                //$.fancybox.close();
            });
        }
    );
}
/**
 * Open the amend order popup
 *
 * @param string url The url to load into the popup
 *
 * @return void
 */
function rom_openMoveOrderPopup(url)
{
    $.fancybox({
        autoDimensions: false,
        autoScale: false,
        content: 'Loading... please wait...',
        height:150,
        width: 400
    });

    try{console.log(url);}catch(e){}

    $.post(
        url,
        null,
        function( response ) {
            $('#fancybox-content').html(response);
            $('#fancybox-content input[type=submit]').button();
            //$('#fancybox-content select').chosen();
            $('#fancybox-content input[type=submit]').click(function(){
                $('form[name=AdminList]').append('<input type="hidden" name="newSession" value="'+$('#newsess').val()+'"/>');
                $('form[name=AdminList]').append('<input type="hidden" name="moveOrder" value="'+$('input[name=moveOrder]').val()+'"/>');
                $('form[name=AdminList]').append('<input type="hidden" name="ajaxsubmit" value="true"/>');
                $('form[name=AdminList]').submit();
                $('#fancybox-content').html('<p>Processing... please wait...');
                //$.fancybox.close();
            });
        }
    );
}
/**
 * Pole for the current session item statuses
 *
 * @return void
 */
function rom_poleCurrentSessionStatuses()
{
    $.ajax({
        url: "/assets/popups/ajax-session-status.php",
        cache: false,
        success: function( html ) {
            $('#activeSession').replaceWith(html);
        }
    });
}
/**
 * Fancybox wrapper
 *
 * @param string         content The content to fill the popup with
 * @param string|integer width   The width of the popup
 *
 * @return void
 */
function rom_popup(content, width)
{
    $.fancybox({
        autoDimensions: false,
        autoScale: false,
        content: content,
        width: width
    });
}
function rom_updateUserQuota(input, id)
{
    try{console.log('Update quota: {id:'+id+',newquota:'+input.value+'}');}catch(e){}
    $input = $(input);
    $input.prev().remove();
    $input.before('<img src="/assets/images/icons/ajax-loader-circle-ball.gif" align="right"/>');
    $.post(
        '/assets/popups/reader-info.php',
        {ID:id,newquota:input.value},
        function( response ) {
            try{console.log(response);}catch(e){}
            $input.prev().remove();
        }
    );
}
/**
 * Set a user live
 *
 * @param object a  The source object (anchor)
 * @param string id The userID
 * @param string t  The field to update (0=status, 1=verified)
 *
 * @return void
 */
var $statusUpdateAnchor = null;
function rom_updateUserStatus(a,id,mode)
{
    if($statusUpdateAnchor!=null)
    {
        return;
    }

    $statusUpdateAnchor = $(a);
    var status = $statusUpdateAnchor.text();

    if(status=='Verify')
    {
        if(!confirm('Please confirm that you have checked the users ID with signature and current address (e.g. driving licence)'))
        {
            $statusUpdateAnchor = null;
            return;
        }
    }

    $statusUpdateAnchor.html('<img src="/assets/images/icons/ajax-loader-circle-ball.gif"/>');

    $.ajax({
        success:function(data){
            $statusUpdateAnchor.parent().html(data);
        },
        url:'/assets/popups/update-status.php?id='+id+'&mode='+mode+'&status='+status
    });

    return;
}
function rom_updateUserStatusCallback(result)
{
    $statusUpdateAnchor.parent().html(result.updateStatus ? 'Updated' : 'Not Updated');
    $statusUpdateAnchor = null;
}
function rom_updateUserStatusClosePopup()
{
    $.fancybox.close();
}
/**
 * Show the block book section and hide the buckets
 *
 * @param a
 *
 * @return void
 */
function rom_showBlockBook(a)
{
    $a = $(a);
    $display = $('#blockbook-display');
    if(!$display.is(':visible'))
    {
        $('#blockbook *').removeClass('onloadhide');
        $a.parent().css({backgroundImage:$a.parent().css('backgroundImage').replace('administrator','cross')});
        $a.text('I want to choose individual sessions');
        $("input[name='preferred_date_display']").parent().hide();
        $('#buckets-display').hide();
        $('.help.standard').hide();
        $('.help.staff').fadeIn();
        $display.fadeIn();
    }
    else
    {
        $a.parent().css({backgroundImage:$a.parent().css('backgroundImage').replace('cross','administrator')});
        $a.text('I would like to block book with a start and end date');
        $("input[name='preferred_date_display']").parent().fadeIn();
        $('#buckets-display').fadeIn();
        $('.help.standard').fadeIn();
        $('.help.staff').hide();
        $('input[name=end_date]').val('');$('input[name=end_date_display]').val('');
        $('input[name=start_date]').val('');$('input[name=start_date_display]').val('');
        $display.hide();
    }
}
