// Prepend... so reverse order!!!
// @codekit-prepend ./init.js
// @codekit-prepend ../vendor/chosen.jquery.js
// @codekit-prepend ../vendor/jquery.fancybox-1.3.4.js
// @codekit-prepend ../vendor/jquery.flexslider.js
// @codekit-prepend ../vendor/jquery.equal-height-columns.js
// @codekit-prepend ../vendor/html5.js
// @codekit-prepend ../vendor/jquery-ui-1.8.9.custom.min.js
// @codekit-prepend ../vendor/jquery-migrate-1.2.1.js
// @codekit-prepend ../vendor/jquery-1.11.0.js


/************* JQUERY TRIGGERS ***********/
jQuery(document).ready(
    function($) {
        $("ul.sub-menu").parent().addClass('parent');
        doEqualHeights();
    }
);
jQuery(window).load(
    function() {
        $("#flexslider-1").flexslider({animation: 'fade'});
        doEqualHeights();
    }
);

jQuery(window).resize(function(){

    try{
        doPanelResize();
        doEqualHeights();
        $('.page_map iframe').each(function(i,v){
            var $v = $(v);
            $v.css({height:$(window).height()-$v.offset().top});
            $('html').css({overflow:'hidden'});
        });
        /*
         * Size the contact button: It is sized to fill the gap so that the social media section
         * aligns-left with the search form. This could not be done reliably in CSS alone, so
         * use JS. Note to self re. position:absolute: paddingRight is not reliable because of
         * pixel rounding issues, so force it with css-left
         */
        $('header li.contact').css({left:0,paddingRight:0,position:'relative'});// reset
        if($(window).width()>=1150)// Only do this for larger viewports
        {
            $('header li.contact').css({
                left:$('header form').offset().left,
                paddingRight: $('header form').width() - $('header li.contact').width() - $('header li.twitter').width() - $('header li.facebook').width() - $('header li.feed').width(),
                position:'absolute'
            });
        }
    }catch(e){

    }
});
jQuery(document).ready(function(){
    init();
    $(window).trigger('resize');
});
/************FUNCTIONS***********/
/**
 * Initialise
 */
function init() {
    //maybeShowSearchTour();
    doPanel();


    // Button Switches: Give button/anchor a class like mytoggleitem-switch, and it will slideToggle #mytoggleitem
    $("button[class*='-switch'],a[class*='-switch']").click(function() {
        $(this).toggleClass('on');
        var classList=$(this).attr('class').split(/\s+/);
        $.each(classList, function(index, item){
            if (item.indexOf("-switch") !== -1) {
                var id = item.substr(0, item.length - 7);
                $("#"+id).slideToggle();
            }
        });
        return false;
    });

    // Show/hide advanced search
    $('#ast').click(function(e){
        var $frm = $('form.advanced');
        var $el = $(e.currentTarget);
        var top = $el.offset().top + $el.height() + 9;
        if($frm.is(':visible'))
        {
            $el.parent().removeClass('selected');
        }
        else
        {
            $el.parent().addClass('selected');
        }
        $frm.css({
            top:top
        }).stop().slideToggle().find('legend').css({
            display:'block',
            left:$el.offset().left - $frm.offset().left,
            top:0,
            width:$el.outerWidth()-1
        });// The legend's just hanging around doing nothing, so use it to create a gap in the border
    });
}

function doPanel() {
    var showTimeout = null;
    var hideTimeout = null;


        $('header nav.menu li a').mouseenter(function () {
            clearTimeout(hideTimeout);
            var $this = $(this);
            var $panel = $('[data-link="'+$this.attr('href')+'"]');
            if($('.panel.active').length) {
                $('.panel').hide();
                $('.panel.active').removeClass('active');
                if($(window).width() > 900) $panel.show().addClass('active');
                $('.nav__item-hovered').removeClass('nav__item-hovered');
                $this.parent().addClass('nav__item-hovered');
            } else {
                showTimeout = setTimeout(function(){
                    $('.panel').hide();
                    $('.panel.active').removeClass('active');
                    if($(window).width() > 900) $panel.show().addClass('active');
                    $('.nav__item-hovered').removeClass('nav__item-hovered');
                    $this.parent().addClass('nav__item-hovered');
                }, 250);
            }
        });
        $('header nav.menu li a').mouseleave(function(e){
            if(!$('.panel.active').length) {
                clearTimeout(showTimeout);

                hideTimeout = setTimeout(function(){
                    $('.panel').hide();
                    $('.panel.active').removeClass('active');
                    $('.nav__item-hovered').removeClass('nav__item-hovered');
                }, 500);
            }
        });

        $('.panel > div').mouseleave(function(e){
            clearTimeout(showTimeout);

            hideTimeout = setTimeout(function(){
                $('.panel').hide();
                $('.panel.active').removeClass('active');
                $('.nav__item-hovered').removeClass('nav__item-hovered');
            }, 500);
        });
}

function doPanelResize() {
    {
        $('#overlay .panel').hide();
        $('.nav__item-hovered').removeClass('nav__item-hovered');
        return;
    }
}
/**
 * Make various modules the same height
 * (Boxes etc)
 */
function doEqualHeights() {

    if($(window).width() < 900)
    if($(window).width() < 900)
    // Maintain equal heights for boxes etc
    if($(window).width() < 900) /* baby-bear */
    {
        if($(".equalTop > *:first-child").length > 0) {
            $(".equalTop > *:first-child").equalHeightColumns('destroy');
        }
        if($(".boxes.equal > div").length > 0) {
            $(".boxes.equal > div").equalHeightColumns('destroy');
        }
    }
    else
    {
        if($(".equalTop > *:first-child").length > 0) {
            $(".equalTop > *:first-child").equalHeightColumns();
        }
        if($(".boxes.equal > div").length > 0) {
            $(".boxes.equal > div").equalHeightColumns({
                speed : 200,
                outer : true,
                outerMargin: false  
            });
        }
    }
}

function killTour()
{
    if(confirm('Are you sure you want to end the tour?'))
    {
        MOB.tour.stop();
        createCookie('showtour', false, 10);
    }
}
/**
 * Create a search-tour
 */
function maybeShowSearchTour(){

    if(readCookie('showtour')=='false')
    {
        try{console.log('Tour aborted by cookie directive')}catch(e){}
        return;
    }

    setTimeout(function(){
        var t = 5;
        MOB.tour.addItem({
            target: $('.cb_facet_expander.sortby'),
            html: '<h1><a href="#" onclick="killTour();"></a>Sort the Results</h1>Select sort options here.',
            duration: t,
            position: MOB.tour.POSITION_LEFT_TOP,
            onshow: function(){
                this.$target.parent().siblings().stop().animate({opacity:0.1});
                this.$target.parent().animate({opacity:1})
            },
            onhide: function(){
                this.$target.parent().siblings().css({opacity:1});
            }
        });
        MOB.tour.addItem({
            target: $('.cb_facet_expander.resultsfrom'),
            html: '<h1><a href="#" onclick="killTour();"></a>Type of results</h1>Results can come from webpages, database or both. You can also view the results on the Building Stones map.',
            duration: t,
            position: MOB.tour.POSITION_LEFT_TOP,
            onshow: function(){
                this.$target.parent().siblings().stop().animate({opacity:0.1});
                this.$target.parent().animate({opacity:1})
            },
            onhide: function(){
                this.$target.parent().siblings().css({opacity:1});
            }
        });
        MOB.tour.addItem({
            target: $('.cb_facet_expander.resultswith'),
            html: '<h1><a href="#" onclick="killTour();"></a>Additional filter options</h1>Additional filter options are shown here. E.g. you can show only records with images.',
            duration: t+2,
            position: MOB.tour.POSITION_LEFT_TOP,
            onshow: function(){
                this.$target.parent().siblings().stop().animate({opacity:0.1});
                this.$target.parent().animate({opacity:1})
            },
            onhide: function(){
                this.$target.parent().siblings().css({opacity:1});
            }
        });
        MOB.tour.addItem({
            target: $('.cb_facet_expander.facets'),
            html: '<h1><a href="#" onclick="killTour();"></a>Facet Filters</h1>Filter by facets here. Expand the facet fields by click on the headers and then check additional items to filter the results.',
            duration: t+2,
            position: MOB.tour.POSITION_LEFT_TOP,
            onshow: function(){
                this.$target.parent().siblings().stop().animate({opacity:0.1});
                this.$target.parent().animate({opacity:1})
            },
            onhide: function(){
                this.$target.parent().siblings().css({opacity:1});
            }
        });
        MOB.tour.start();
    }, 1000);
}

function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = escape(name) + "=" + escape(value) + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = escape(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return unescape(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, "", -1);
}
