$(document).ready(function(){
    function recalculate()
    {
        var totalnet = 0;
        var totalvat = 0;
        var key = 0;
        gatewayClearInputs();
        $('[name*=item\\[]').remove();
        $('#checkout table.items tr').each(function(i,v){
            var $tr = $(v);
            var subtotalnet = $tr.data('net');
            var subtotalvat = $tr.data('vat');
            var subtotal = parseFloat(subtotalnet) + parseFloat(subtotalvat);
            var subtotalQuant = subtotal * $tr.data('qnty');
            $tr.find('.total').html(subtotalQuant.toFixed(2));
            totalnet += +subtotalnet * +$tr.data('qnty');
            totalvat += +subtotalvat * +$tr.data('qnty');
            key = i + 1;
            $('#checkout')
                .append(gatewayMakePaymentInput(key, $tr.data('auditref'), $tr.data('name'), subtotalnet, subtotalvat, $tr.data('qnty'), $tr.data('nominal')))
                .append('<input name="item['+key+'][id]" value="'+$tr.data('id')+'" type="hidden"/>')
                .append('<input name="item['+key+'][quantity]" value="'+$tr.data('qnty')+'" type="hidden"/>')
                .append('<input name="item['+key+'][vat]" value="'+$tr.data('vat')+'" type="hidden"/>')
                .append('<input name="item['+key+'][net]" value="'+$tr.data('net')+'" type="hidden"/>');
        });
        $('#checkout table.totals .net').html(totalnet.toFixed(2));
        $('#checkout table.totals .vat').html(totalvat.toFixed(2));
        $('#checkout table.totals .gross').html((totalnet+totalvat).toFixed(2));
        $('#checkout table.totals .buttons').append(gatewayMakePaymentButtons());
        $('[name=PaymentTotal]').val((totalnet+totalvat).toFixed(2));
    }
    function removeItem(e)
    {
        var $el = $(e.currentTarget);
        var id = $el.closest('tr').data('id');
        $('.item[data-id='+id+']').removeClass('added');
        $el.closest('tr').remove();
        recalculate();
        if(!$('#checkout table.items tr').length)
        {
            $('body').removeClass('itemsadded');
        }
    }
    function addItem(e, item)
    {
        if(undefined===item)
        {
            var $el = $(e.currentTarget);
            if($el.data('net')=='0.00')
            {
                $.fancybox({
                    content:$('#adhoc-form').data({
                        'id': $el.data('id'),
                        'nominal': $el.data('nominal')
                    })
                });
            }
            else
            {
                $('body').addClass('itemsadded');
                var $checkoutItem = $('#checkout tr[data-id='+$el.data('id')+']');
                if(!$checkoutItem.length)
                {
                    $.ajax({
                        url:'/cashsale?autonum=1',
                        success:function(d){
                            $('<tr data-auditref="'+d+'" data-id="'+$el.data('id')+'" data-qnty="1" data-name="'+$el.data('name')+'" data-net="'+$el.data('net')+'" data-vat="'+$el.data('vat')+'" data-nominal="'+$el.data('nominal')+'"><td><i class="fa fa-trash" title="Remove"></i></td><td>'+$el.data('name')+'</td><td class="qnty"></td><td class="total"></td></tr>')
                                .appendTo('#checkout table.items')
                                .find('.fa-trash')
                                .click(removeItem);
                            recalculate();
                    }});
                }
                else
                {
                    var qnty = parseInt($checkoutItem.data('qnty')) + 1;
                    $checkoutItem
                        .data('qnty', qnty)
                        .find('.qnty')
                        .html('(x'+qnty+')');
                    recalculate();
                }
                $el.addClass('added');
            }
        }
        else
        {
            $('body').addClass('itemsadded');
            $('<tr data-auditref="'+item.auditref+'" data-id="'+item.id+'" data-qnty="1" data-name="'+item.name+'" data-net="'+item.net+'" data-vat="'+item.vat+'" data-nominal="'+item.nominal+'"><td><i class="fa fa-trash" title="Remove"></i></td><td>'+item.name+'</td><td class="qnty"></td><td class="total"></td></tr>')
                .appendTo('#checkout table.items')
                .find('.fa-trash')
                .click(removeItem);
            recalculate();
        }
    }
    function saveOrder(e)
    {
        var success = false;
        console.log('Saving order');
        console.log($('#editForm').serialize());
        $.ajax({
            url:'/cashsale',
            data: $('#editForm').serialize(),
            method: 'POST',
            success: function(data){
                console.log(data);
                if(data.error)
                {
                    alert(data.error);
                }
                else if(String(data.result).match(/[0-9]+/))
                {
                    $('[name=ReturnURL]').val($('[name=ReturnURL]').val()+'&sale_id='+data.result); // For the callback
                    success = true;
                }
                else
                {
                    alert('Unknown error');
                }
            },
            async: false
        });
        return success;
    }

    // Add handlers
    $('#adhoc-form .add').unbind().click(function(e){
        var errors = [];
        if(!$('#adhoc-name').val())
        {
            errors.push($('#adhoc-name').data('error'));
        }
        if(!$('#adhoc-amount').val().match(/^[0-9\.]+/))
        {
            errors.push($('#adhoc-amount').data('error'));
        }
        if(errors.length)
        {
            alert(errors.join("\n"));
            return false;
        }
        else
        {
            var rate, vat=0, net = parseFloat($('#adhoc-amount').val());

            $.ajax({
                url:'/cashsale?autonum=1',
                success:function(d){
                    auditref = d;
            if($('#adhoc-vat').is(':checked'))
            {
                rate = parseFloat($('#adhoc-vat').data('rate'));
                        vat = net * rate;
                net-= vat;
            }
            addItem(null, {
                id: $('#adhoc-form').data('id'),
                        auditref: auditref,
                name: $('#adhoc-name').val(),
                nominal: $('#adhoc-form').data('nominal'),
                net: net,
                vat: vat
            });
        }
            });
        }
        $('#adhoc-form .cancel').trigger('click');
        return false;
    });
    $('#adhoc-form .cancel').unbind().click(function(e){
        $.fancybox.close();
        $('#adhoc-form').appendTo('#adhoc-form-container');
        return false;
    });

    if(window.location.href.indexOf('cashsale')!==-1)
    {
    // Eye Candy
    // Auto size the tabs and remove empty ones
        $('.ui-tabs-nav li:empty').remove();
        $('.ui-tabs-nav li').css({width:( ( ( 1 / $('.ui-tabs-nav li').length ) * 100 ) - 0.1 )+'%' });
        $('#editFormTabs').tabs('option','select',function(event, ui){
            $('.watermark').removeClass('sliding');
            $(ui.panel).find('.watermark').addClass('sliding');
        });
        $('<div id="help">Double click items to add them to the checkout<br/> Close this Sales Window after you have finished your transaction to return to the main website.</div>').appendTo('body.cashsale').fadeIn();
        $('#editForm').unbind().submit(saveOrder);
        $('.item').unbind().dblclick(addItem);
    }
});
